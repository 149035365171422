import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import 'bootstrap';
import './application.scss'

Rails.start()
ActiveStorage.start()

window.$ = jQuery;
require.context('../images', true)
// const images = require.context('images/', true)
// const imagePath = (name) => images(name, true)
require("trix")
require("@rails/actiontext")
